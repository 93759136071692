/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../request.js";
import t from "../core/Error.js";
import { normalizeMessageBundleLocale as n } from "./messages.js";
async function r(e, r, s, i) {
  const a = r.exec(s);
  if (!a) throw new t("esri-intl:invalid-bundle", `Bundle id "${s}" is not compatible with the pattern "${r}"`);
  const c = a[1] ? `${a[1]}/` : "",
    l = a[2],
    w = n(i),
    h = `${c}${l}.json`,
    u = w ? `${c}${l}_${w}.json` : h;
  let d;
  try {
    d = await o(e(u));
  } catch (f) {
    if (u === h) throw new t("intl:unknown-bundle", `Bundle "${s}" cannot be loaded`, {
      error: f
    });
    try {
      d = await o(e(h));
    } catch (f) {
      throw new t("intl:unknown-bundle", `Bundle "${s}" cannot be loaded`, {
        error: f
      });
    }
  }
  return d;
}
async function o(t) {
  if (null != a.fetchBundleAsset) return a.fetchBundleAsset(t);
  const n = await e(t, {
    responseType: "text"
  });
  return JSON.parse(n.data);
}
class s {
  constructor({
    base: e = "",
    pattern: t,
    location: n = new URL(window.location.href)
  }) {
    let r;
    r = "string" == typeof n ? e => new URL(e, new URL(n, window.location.href)).href : n instanceof URL ? e => new URL(e, n).href : n, this.pattern = "string" == typeof t ? new RegExp(`^${t}`) : t, this.getAssetUrl = r, e = e ? e.endsWith("/") ? e : e + "/" : "", this.matcher = new RegExp(`^${e}(?:(.*)\\/)?(.*)$`);
  }
  fetchMessageBundle(e, t) {
    return r(this.getAssetUrl, this.matcher, e, t);
  }
}
function i(e) {
  return new s(e);
}
const a = {};
export { s as JSONLoader, i as createJSONLoader, a as test };